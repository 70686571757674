.r-form {
  .form-group {
    text-align: left;
    position: relative;
    margin-bottom: 0.5rem !important;
    .inner-form {
      .field-error-style {
        border: 1px solid #f16063 !important;
        box-shadow: 0px 0px 1px 0px rgba(241, 96, 99, 0.2),
          0px 1px 2px 0px rgba(241, 96, 99, 0.08) !important;
      }

      .form-control:disabled {
        background: #f6f6f6;
      }

      .error-d {
        .flex {
          gap: 5px;

          img {
            margin-top: 2px;
            width: 15px;
            height: 14px;
          }
        }
      }
      .small {
        color: var(--secondary);
        text-align: end;
        margin-top: 5px;
        cursor: pointer;
        margin-left: auto;
      }
      .eye-icon-img {
        position: absolute;
        bottom: 40px;
        right: 14px;
        cursor: pointer;
      }
      .field-error {
        bottom: 39px;
      }

      label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #425466;
        margin-bottom: 8px;
        margin-top: 32px;
      }

      input {
        height: 46px;
        border: none !important;
        outline: none !important;
        box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
          0px 1px 2px 0px rgba(50, 50, 71, 0.08);

        padding-right: 30px; /* Add space for the eye icon */

        & ::placeholder {
          font-family: var(--inter);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 13px;
          color: #7a828a;
        }
      }

      input.form-control:focus {
        box-shadow: 0px 0px 0px 2px #b3b3b34d !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .r-form {
    .form-group {
      .inner-form {
        label {
          margin-top: 16px;
        }
      }
    }
  }
}

.input-select-fo {
  img {
    margin-bottom: 11px !important;
  }
}
