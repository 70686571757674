.dashboard-ctn {
  .dasboard-barContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inner-ctn {
    padding: 30px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;

    .media-comp-list-dashboard {
      .media-companies .media-inner {
        margin-top: 0px;
        padding: 10px 0px 10px 0px;
      }
    }

    .goldie-row-tc > .col-lg-3:nth-child(9),
    .goldie-row-tc > .col-lg-3:nth-child(10) {
      width: 50% !important;
    }
  }

  .goldie-col {
    margin-top: 32px;
  }

  .shadow-unset {
    box-shadow: unset !important;
    filter: none !important;
  }

  .world-map-bx {
    height: 453px;
  }
  table {
    tr {
      th {
        width: 20% !important;
      }
    }
  }

  .legend-circle {
    .circle {
      background-color: #ff92ae;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .leg-title {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #7a7a9d;
    }
  }
}

@media only screen and (min-width: 1900px) {
  .dashboard-ctn {
    .world-map-bx {
      height: auto;
    }
  }
}

@media only screen and (max-width: 1430px) {
  .on-board-col,
  .world-map-col {
    width: 50% !important;
  }
}

@media screen and (max-width: 1350px) {
  .dashboard-ctn {
    .c-col {
      padding: 5px !important;
      width: 33% !important;
      flex: unset;

      .box-hoc {
        padding: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-ctn {
    .c-col {
      width: 49% !important;
    }
  }
}

@media only screen and (max-width: 1124px) {
  .on-board-col,
  .world-map-col {
    width: 49% !important;
    min-height: 382px !important;
  }

  .dashboard-ctn {
    .world-map-bx {
      height: 446px !important;
      min-height: 382px !important;
    }
    .goldie-col {
      width: 24.5% !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .dashboard-ctn {
    .c-col {
      width: 33% !important;
    }
  }
  .dasboard-barContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    flex-wrap: wrap;
    .css-1tc1fc6-control,
    .css-1qpsdfz-control {
      width: 200px !important;
    }
  }
}

@media screen and (max-width: 875px) {
  .dashboard-ctn {
    .inner-ctn {
      padding: 25px 18px 80px 18px;
    }
    .c-col {
      width: 49% !important;
      flex: unset;
    }

    .goldie-row-tc {
      .goldie-col {
        margin-top: 16px;
        width: 33% !important;
        flex: unset;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .on-board-col,
  .world-map-col {
    width: 100% !important;
  }

  .dashboard-ctn {
    .world-map-bx {
      height: auto !important;
      min-height: 100% !important;
    }
    .c-col {
      padding: 15px 15px 0px 15px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard-ctn {
    .inner-ctn {
      padding: 25px 18px 80px 18px;
    }
  }
}

@media only screen and (max-width: 610px) {
  .dashboard-ctn {
    .c-col {
      width: 100% !important;
      flex: unset;
    }

    .goldie-row-tc {
      .goldie-col {
        width: 49% !important;
        flex: unset;
      }
    }
    .earn-chart {
      margin-top: 20px;
    }

    .inner-ctn {
      .goldie-row-tc > .col-lg-3:nth-child(9),
      .goldie-row-tc > .col-lg-3:nth-child(10) {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .dasboard-barContent {
    flex-wrap: wrap;
    gap: 10px;
    .css-1tc1fc6-control,
    .css-1qpsdfz-control {
      width: 120px !important;
    }
  }

  .dashboard-ctn {
    .goldie-row-tc {
      .goldie-col {
        width: 100% !important;
      }
    }
  }
}
