.agency-subs-box {
  background: #ffffff;
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
  margin-top: 16px;

  //   min-height: 98px;
  .img-tilte {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .inner-row {
    display: flex;
    flex-direction: column;

    h2 {
      margin: 3px 0px 5px 0px;
    }
  }

  .cmp-img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
}

.agency-subs-box.extra-padding {
  padding: 24px 15px;
}
