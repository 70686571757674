.select-ctn {
  .css-1u9des2-indicatorSeparator {
    display: none !important;
  }

  .s-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #16192c;
  }

  [class*="placeholder"] {
    padding-bottom: 11px;
  }
}

@media only screen and (max-width: 570px) {
  .select-ctn {
    .r-select {
      [class*="control"] {
        width: 176px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .select-ctn {
    .r-select {
      [class*="control"] {
        width: 136px;
      }
    }
  }
}
