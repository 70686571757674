.custom-modal {
  text-align: center;

  .modal-content {
    background-color: #fcfafa;
    width: 360px;
    margin: auto;
    padding: 0px;
  }
  .cross-img {
    text-align: right;
    padding-top: 20px;
    img {
      width: 20px;
      height: 19.863px;
      cursor: pointer;
    }
  }
}

.for-media-popup {
  .modal-content {
    width: 100%;
    padding: 0px 40px 47px 40px;
    background-color: #fcfafa;
  }
}

@media only screen and (max-width: 600px) {
  .for-media-popup {
    .modal-content {
      width: 100%;
      padding: 0px 15px 47px 15px;
      background-color: #fcfafa;
      margin: 20px;
    }
  }
}
