.comp-with-subs {
  cursor: pointer;

  .table-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
  .agency-t {
    font-family: var(--inter);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    color: #7a7a9d;
    cursor: pointer;
  }

  .one-line-txt {
    display: -webkit-box;
    max-height: 40px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .low-w {
    color: #425466;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }
}
