.embed-message {
  border-radius: 10px;
  width: 100%;
  background-color: #f16063;
  padding: 16px;
  margin: 20px 0;

  img {
    margin-right: 10px;
  }

  span {
    color: #fff;
    text-align: left;
  }
}
