.top-bar {
  background-color: #fff;
  padding: 1.5rem;
  position: fixed;
  height: 104px;
  width: calc(100% - 250px);
  top: 0;
  z-index: 7;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

  .toggle-btn {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .top-bar {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-left: 0px;
    cursor: pointer;

    .toggle-btn {
      display: block;
      width: 18px;
      height: 15px;
    }
  }
}
