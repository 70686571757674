.add-media-company {
  .uploader-box {
    .note {
      font-family: var(--inter);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #425466;
      text-align: left;
    }
  }

  .r-form {
    margin-top: 26px;
    .form-group {
      .inner-form {
        .eye-icon-img {
          bottom: 15px;
          right: 14px;
        }
        .field-error {
          bottom: 36px;
        }
      }
    }
  }

  .mb-3 {
    margin-bottom: 0.5rem !important;
  }

  .r-form label {
    margin-top: 0px !important;
  }

  .terms-condition {
    margin-top: 30px;
    align-items: start;
    .tc-txt {
      font-family: var(--inter);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #425466;
      text-align: left;

      span {
        font-weight: 600;
        color: #27272e;
      }
    }
  }
}

@media only screen and (max-width: 467px) {
  .add-media-company {
    .uploader-box {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
