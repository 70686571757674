.settings-container {
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;

    .ref-reg {
      .r-form {
        .form-group {
          width: 48%;
        }
      }
    }
  }

  .r-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .form-group {
      width: 24%;
    }
  }

  .save-btn {
    max-width: 82px;
    margin: auto;
  }
}

@media screen and (max-width: 1024px) {
  .settings-container {
    .inner-ctn {
      padding: 10px 47px 91px 47px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .settings-container {
    .inner-ctn {
      padding: 10px 16px 91px 16px;
    }
    .r-form {
      flex-wrap: wrap;
      margin-bottom: 50px;

      .form-group {
        width: 49%;
      }
    }
  }
}

@media only screen and (max-width: 467px) {
  .settings-container {
    .r-form {
      flex-wrap: wrap;
      margin-bottom: 20px;

      .form-group {
        width: 99%;
      }
    }
  }
}
