.status-bage {
  padding: 7px 10px;
  border-radius: 6px;
  border: none !important;

  font-family: var(--inter);
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  max-width: 70px;

  text-align: center;
}

.status-bage.active,
.status-bage.article {
  @extend .status-bage;
  background-color: #deffee;
  color: #66cb9f;
}
.status-bage.inactive,
.status-bage.video {
  @extend .status-bage;
  background-color: #ffe6e4;
  color: #f16063;
}

.status-bage.goldiesWallet {
  @extend .status-bage;
  min-width: 120px;
  background-color: #ebe9ff;
  color: #7d6bff;
}

.status-bage.coming-soon {
  @extend .status-bage;
  min-width: 120px;
  background-color: #ffdde2;
  color: #f91c3b;
}

.status-bage.referral {
  @extend .status-bage;
  min-width: 120px;
  background-color: #fff6d6;
  color: #d2a813;
}

.status-bage.registration {
  @extend .status-bage;
  min-width: 120px;
  background-color: #e7e7ff;
  color: #7671ff;
}
