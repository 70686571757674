.subscriber-wallet {
  .inner-ctn {
    padding: 10px 30px 120px 30px;
    background-color: var(--bg);
    margin-top: 104px;
  }

  .goldie-col {
    margin-top: 32px;
  }

  .col-lg-3:nth-child(13),
  .col-lg-3:nth-child(14) {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1124px) {
  .subscriber-wallet {
    .goldie-col {
      width: 33% !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .subscriber-wallet {
    .goldie-col {
      width: 24.5% !important;
      margin-top: 29px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .subscriber-wallet {
    .goldie-col {
      width: 33% !important;
    }
  }
}

@media only screen and (max-width: 640px) {
  .subscriber-wallet {
    .inner-ctn {
      padding: 15px 16px 90px 16px;
    }
    .goldie-col {
      width: 49% !important;
      margin-top: 12px;
    }

    .col-lg-3:nth-child(13),
    .col-lg-3:nth-child(14) {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 410px) {
  .subscriber-wallet {
    .goldie-col {
      width: 100% !important;
    }
  }
}
