.r-search {
  max-width: 232px;
  position: relative;

  .s-icon {
    position: absolute;
    top: 12px;
    left: 10px;
    z-index: 1;
  }

  input {
    background: #ffffff;
    // height: 36px;
    // box-shadow: 0px 0px 1px 0px #32324733;
    // box-shadow: 0px 1px 2px 0px #32324714;
    padding-left: 10px;
    height: 36px;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
      0px 1px 2px 0px rgba(50, 50, 71, 0.08);

    border-radius: 6px;
    z-index: 3;
  }

  input:focus-visible {
    border: none !important;
    outline: var(--primary) !important;
    box-shadow: 0px 0px 0px 2px rgba(179, 179, 179, 0.3019607843) !important;
  }

  input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    color: #b5b5bd;
    padding-left: 20px;
  }
}
