@import "./dynamic-helper-classes.scss";

:root {
  --primary: #fa1c3c;
  --secondary: #4c6fff;
  --hover: #ff4561;
  --pagination: #ffcad2;
  --bg: #fcfafa;
  --bgtext: #f1f1ff;
  --border: #e1e5e8;
  --placeholder: #e1e1e1;
  --text: #27272e;
  --error: #fa7777;
  --required: #ef5da8;
  --inter700: "InterBold", sans-serif;
  --inter600: "InterBold", sans-serif;
  --inter: "Inter", sans-serif;
}

body {
  font-family: var(--inter);
  margin: 0;
  color: var(--text);
  background: #fcfafa;
}

.layout {
  max-width: 1440px;
  margin: 0 auto;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.ml-100 {
  margin-left: 100px;
}
.cursor {
  cursor: pointer;
}
.d-grid {
  display: grid;
}
.d-flex {
  display: flex;
}
.px-8 {
  padding-inline: 8px;
}
.px-16 {
  padding-inline: 16px;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 136.02%;
  color: #27272e;
  margin: 0;
}
h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #27272e;
  margin: 0;
}
h4 {
  font-family: var(--inter700);
  font-style: normal;
  font-weight: 700;
  font-size: 1.44em;
  color: #222222;
  margin: 0;
}

h5 {
  font-family: var(--inter700);
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0em;
  margin: 0;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}

.mediumUpper {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #16192c;
}

.medium {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #222222;
}

.medium-upper {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #27272e;
}

.lowMedium {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.low {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #425466;
}

.small {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #425466;
}

.table-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border);
  object-fit: cover;
  object-position: top;
  margin-right: 12px;
}

.sixty-twelve {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #27272e;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.align-items-center {
  align-items: center;
}
.required {
  color: var(--required);
}

.flex-d-colm {
  display: flex;
  flex-direction: column;
}

.f-wrap {
  flex-wrap: wrap;
}

.gap-15 {
  gap: 15px;
}

.text-align {
  text-align: center !important;
}

.label {
  font-size: 16px;
  font-family: var(--inter700);
  color: var(--text);
  &-float {
    top: 6px;
    top: 0px;
  }
  line-height: 19.2px;
  text-align: left;
  display: block;
}

.error {
  font-size: 13px;
  color: var(--error);
  margin-top: 2px;
  text-align: left;
}

.space-between {
  justify-content: space-between;
}

@media screen and (max-width: 1080px) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 16px;
  }

  .low {
    font-size: 12px;
  }

  h6 {
    font-size: 12px;
  }

  .mediumUpper {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 23px;
  }
  h2 {
    font-size: 14px;
  }
  .low {
    font-size: 12px;
  }
  h1 {
    font-size: 20px;
  }

  h6 {
    font-size: 10px;
  }

  .mediumUpper {
    font-size: 14.5px;
  }

  .error {
    font-size: 13px;
  }

  .flex {
    gap: 5px !important;
  }
  // h3 {
  //   font-size: 25px;
  // }
  // h4 {
  //   font-size: 22px;
  // }
  // .body-medium {
  //   font-size: 14px;
  // }
  // h5 {
  //   font-size: 20px;
  // }
  // h6 {
  //   font-size: 18px;
  // }
}
